import React, { useEffect } from "react";
import ContentWrapper from "../components/ContentWrapper/ContentWrapper";
import NewFooter from "../components/landing/NewFooter";

export default function AboutUs() {
  // useEffect(() => {
  //   // Set custom page title
  //   document.title = "My Custom Title - Subtitle Here";

  //   // Optional: Reset to default title when component unmounts
  //   return () => {
  //     document.title = "Default Title";
  //   };
  // }, []);

  return (
    <>
      <ContentWrapper
        type={"AboutSection"}
        title={["About", "US"]}
        content={"<h1>Just and HTML</h1>"}
      />

      <NewFooter />
    </>
  );
}
