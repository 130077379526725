const DEP_TYPE = process.env.REACT_APP_DEPLOYMENT || "local";

const SERVER_HOSTNAME =
  (DEP_TYPE === "production"
    ? process.env.REACT_APP_SERVER_HOSTNAME_PROD
    : DEP_TYPE === "development"
    ? process.env.REACT_APP_SERVER_HOSTNAME_DEV
    : process.env.REACT_APP_SERVER_HOSTNAME_LOCAL) || "";

const FRONT_HOSTNAME =
  (DEP_TYPE === "production"
    ? process.env.REACT_APP_FRONT_HOSTNAME_PROD
    : DEP_TYPE === "development"
    ? process.env.REACT_APP_FRONT_HOSTNAME_DEV
    : process.env.REACT_APP_FRONT_HOSTNAME_LOCAL) || "";

const STRIPE_PUBLIC_KEY =
  (DEP_TYPE === "production"
    ? process.env.REACT_APP_STRIPE_PUBLIC_KEY_PROD
    : DEP_TYPE === "development"
    ? process.env.REACT_APP_STRIPE_PUBLIC_KEY_DEV
    : process.env.REACT_APP_STRIPE_PUBLIC_KEY_LOCAL) || "";

const STRIPE_SECRET_KEY =
  (DEP_TYPE === "production"
    ? process.env.REACT_APP_STRIPE_SECRET_KEY_PROD
    : DEP_TYPE === "development"
    ? process.env.REACT_APP_STRIPE_SECRET_KEY_DEV
    : process.env.REACT_APP_STRIPE_SECRET_KEY_LOCAL) || "";

const CDN_LINK =
  DEP_TYPE === "production"
    ? process.env.REACT_APP_CDN_PROD
    : DEP_TYPE === "development"
    ? process.env.REACT_APP_CDN_DEV
    : "";

const PLAY_STORE_LINK = process.env.REACT_APP_PLAY_STORE_LINK;

const APP_STORE_LINK = process.env.REACT_APP_APP_STORE_LINK;

const configuration = {
  mode: DEP_TYPE,
  cdnFront: CDN_LINK,
  apiUrl: SERVER_HOSTNAME,
  appleClientId: "com.barooom.userapp.client",
  appleRedirectUri: FRONT_HOSTNAME,
  googleClientId:
    "1020391111902-fc2ja6c48alh780b0l17trco2dt1984c.apps.googleusercontent.com",
  stripeKey: STRIPE_SECRET_KEY,
  stripePublicKey: STRIPE_PUBLIC_KEY,
  vapidKey:
    "BCjoKlhc791En9xNRcAP9GJC6AMhnGHmCiKcYZ-1TYrz7BTi6YNnsncHq9dPNJjQV3AvUjBHfRlkm4GJ69JZWnE",
  googlePlayStoreLink: PLAY_STORE_LINK,
  appleAppStoreLink: APP_STORE_LINK,
};

export default configuration;
