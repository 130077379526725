import React, { useState } from "react";
import { useSelector } from "react-redux";
import { createStripeAccUrl } from "../../axios/axios";
import { Player } from "@lottiefiles/react-lottie-player";
import LottiePlayer from "../LottiPlayer/LottiePlayer";
import BankAnimation from "../LottiPlayer/Bank_Animation.json";
import BankAnimationPending from "../LottiPlayer/Bank_Animation_Pending.json";
function StripeSetupModal({ setModalShow, ModalShow, message }) {
  const user = useSelector((state) => state.user.user);
  const [loading, setLoading] = useState(false);
  const handleOnboard = async () => {
    setLoading(true);
    try {
      const response = await createStripeAccUrl({ token: user.token });

      const { url, message, type } = response?.data;
      // if(url.trim() !=="" && type===2){
      //     setDisplaymsg(message)
      // }
      if (url) {
        window.location.href = url;
        // window.open(url, '_blank');
      } else {
        console.error("Error: Account link URL not found.");
      }
    } catch (error) {
      console.error("Error creating account link:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center p-4 px-4 overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
        <div className="relative w-auto max-w-3xl mx-auto my-6">
          {/*content*/}
          <div className="relative flex flex-col w-full bg-white outline-none rounded-xl focus:outline-none">
            {/*header*/}
            <button
              className="self-end pt-3 pr-4 text-2xl"
              onClick={() => setModalShow(!ModalShow)}
            >
              {" "}
              &#10005;
            </button>
            <div className="px-4 pb-4">
              {user.connected_acc_setup === "0" && !user.payouts_enabled && (
                <p className="text-lg text-center font-poppins">
                  {message
                    ? message
                    : `You need to provide bank details and personal information for accepting payments, before you can start applying for the gigs.`}
                </p>
              )}
              {user.connected_acc_setup === "0" && user.payouts_enabled && (
                <p className="text-lg text-center font-poppins">
                  {message
                    ? message
                    : `Your account requires further action, otherwise it will be restricted soon.`}
                </p>
              )}
              {user.connected_acc_setup === "1" && (
                <p className="text-lg text-center font-poppins">
                  Your account is in review. We will notify once it is done.
                </p>
              )}

              <div className="flex items-center justify-center w-full gap-3 py-12">
                {user.connected_acc_setup == "0" && (
                  <LottiePlayer src={BankAnimation} />
                )}
                {user.connected_acc_setup == "1" && (
                  <LottiePlayer src={BankAnimationPending} />
                )}
              </div>

              {user.connected_acc_setup == "0" && (
                <button
                  className="w-full h-10 font-normal cursor-pointer bg-[#6772E5] text-white tracking-wide over:shadow-xl rounded-xl"
                  style={{ textAlign: "center", marginTop: "20px" }}
                  onClick={handleOnboard}
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Start Setup"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
    </>
  );
}

export default StripeSetupModal;
