import React from 'react'

function NotFoundBody() {
    return (
        <section className="flex flex-col items-center justify-center py-4">
            <img src="/images/NotFoundLogo.svg" alt="" />
            <h1 className="text-6xl font-medium font-poppins">Page <span className="text-establishment-red">Not</span> Found</h1>
        </section>)
}

export default NotFoundBody