import React from "react";
import ContentWrapper from "../components/ContentWrapper/ContentWrapper";
import NewFooter from "../components/landing/NewFooter";
import NotFoundBody from "../components/NotFound/NotFoundBody";
import { useSelector } from "react-redux";
import PageHeaderNav from "./PageHeaderNav";
import BartenderWrapperDash from "../components/dashboard/WrapperDash";
import DashHeader from "../components/dashboard/DashHeader";

const NotFound = () => {
  const user = useSelector((state) => state.user?.user)
  return (
    <>
      {!user &&
        <>
          <PageHeaderNav textColor={'text-black'} />
          <NotFoundBody />
          <NewFooter />
        </>
      }
      {
        user &&
        <BartenderWrapperDash>
          <DashHeader
            leftContent={
              <>
                <div className="pt-3 my-auto uppercase grow">
                  <span className=" text-neutral-700">Welcome</span>{" "}
                  <span className="text-yellow-400 ">{user?.name}</span>
                </div>
              </>
            }
          />
          <section className="flex flex-col items-center justify-center py-4">
            <NotFoundBody />
            <button className="h-14 px-8 py-2 mt-4 text-xl rounded-full bg-yellow-1 w-[30%]">Back To Dashboard</button>

          </section>
        </BartenderWrapperDash>

      }
    </>
  );
};

export default NotFound;
