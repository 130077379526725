import React, { useEffect, useState } from "react";
import { FetchBlogsList } from "../axios/axios";
import "./Blogs.css";
import { useSelector } from "react-redux";
import style from "../components/ContentWrapper/ContentWrapper.module.css";
// import style from "../component/ContentWrapper/ContentWrapper.module.css";
import { Link, useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import Footer from "../components/landing/Footer";
import NewFooter from "../components/landing/NewFooter";
import configuration from "../config/configuration";

const Blogs = () => {
  const user = useSelector((state) => state?.user?.user);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setdata] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });

  const getBlogs = () => {
    FetchBlogsList({ page: 1 })
      .then((result) => {
        let res = result.data;
        let x = { ...data };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          history: [...res.history],
        };
        setdata(x);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getBlogs();
  }, []);

  return (
    <>
      <div
        style={{
          background: `url(/AboutBarrom/backgroundImg.png)`,
          backgroundSize: "cover",
        }}
      >
        {/* <header className={style.header}>
          {user ? (
            <></>
          ) : (
            <>
              <Link to={"/login"} className={style.changeColor}>
                Login
              </Link>
              <Link to={"/signup"} className={style.changeColor}>
                Signup
              </Link>
            </>
          )}
        </header> */}
        <Link to={"/"}>
          <img
            loading="lazy"
            className={style.barrom}
            src={"/LandingPageImages/Barrom.svg"}
            alt=""
          />
        </Link>
        <div className={style.lowerPart}>
          <div className={style.backgroundImg}>
            <h3>
              Barooom <span>Blogs</span>
            </h3>
          </div>
        </div>
      </div>
      <div className="blogs-container">
        <div className="mt-10 blogs-header"></div>
        {!loading ? (
          <div className="pt-8 blogs-list">
            {data.history.length > 0 &&
              data.history.map((elem) => (
                <div
                  key={elem._id}
                  className="cursor-pointer blog-item"
                  onClick={() => navigate(`/blogs/${elem._id}`)}
                >
                  <img
                    loading="lazy"
                    className="blog-img"
                    src={`${configuration.cdnFront}/${elem?.blogImg}`}
                    alt={elem.title}
                  />
                  <p className="blog-title">{elem.title}</p>
                </div>
              ))}
          </div>
        ) : (
          <div className="loading-container">
            <CircularProgress />
            <h1>Loading</h1>
          </div>
        )}
      </div>
      <NewFooter />
    </>
  );
};

export default Blogs;
